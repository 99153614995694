.gridContainer {
    display: flex;
    height: 100%;
    position: relative;

    .grid {
        height: 100%;
        display: flex;
        flex: 1;
        flex-direction: column;
        background: var(--secondary-background-color);
        position: relative;

        .header {
            grid-area: header;
            display: flex;
            flex-direction: column;

            .titlecontainer {
                display: flex;
                margin-bottom: 30px;
            }

            .actionsContainer {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                height: 76px;
                margin-bottom: 4px;

                .actions {
                    display: flex;
                    justify-content: flex-end;
                    padding: 10px 17px;

                    .button {
                        margin: 0 10px;

                        &:first-child {
                            margin-left: 0;
                        }
                    }

                    .filter {
                        padding: 6px 8px;
                        margin: 0;
                        opacity: 0.9;
                    }
                }
            }
        }

        .body {
            grid-area: body;
        }
    }
}

/* .gridContent {
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 500px;
        width: 100%;
        overflow: hidden;
        position: relative;

        .checkboxHeader,
        .checkboxCell {
            border-right: 2px solid rgba(0, 0, 0, 0.1) !important;
        }

        .gridHeader {
            .quickFilter {
                display: flex;
                align-items: center;
                height: 60px;
                padding: 10px 17px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                line-height: 50px;

                svg {
                    color: var(--light-text-color);
                    font-size: 0.75rem;
                }

                p {
                    color: var(--light-text-color);
                    font-weight: 500;
                    margin-left: 12px;
                    margin-top: 2px;
                }
            }

            .actionContainer {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                height: 76px;
                margin-bottom: 4px;

                .selectAll {
                    display: flex;
                    align-items: center;
                    padding-right: 15px;
                    margin-right: 10px;
                    position: relative;

                    &::after {
                        position: absolute;
                        content: '';
                        right: 0;
                        height: 80%;
                        width: 1px;
                        background: rgba(0,0,0,0.1);
                        top: 10%;
                    }

                    .label {
                        margin-left: 8px;
                        color: var(--medium-text-color);
                        font-size: 0.75rem;
                    }
                }

                .actions {
                    display: flex;
                    justify-content: flex-end;
                    padding: 10px 17px;

                    .button {
                        margin: 0 10px;

                        &:first-child {
                            margin-left: 0;
                        }
                    }

                    .filter {
                        padding: 6px 8px;
                        margin: 0;
                        opacity: 0.9;
                    }
                }
            }

            .context {
                display: flex;
                border: solid rgba(0, 0, 0, 0.1);
                border-width: 1px 0 1px 0;
                font-size: 12px;
                color: var(--light-text-color);
                background: #f8f8f8;

                & > * {
                    border-right: 1px solid rgba(0, 0, 0, 0.1);
                    padding: 10px;
                    align-items: center;

                    & > *:not(:last-child) {
                        margin-right: 8px;
                    }
                }

                & > *:first-child {
                    padding: 10px 7px 10px 7px;
                }

                .clearIcon {
                    font-size: 14px;

                    &:hover:not(.inactive) {
                        color: var(--primary-theme-color);
                        cursor: pointer;
                    }
                }

                .inactive {
                    color: var(--xlight-text-color);
                }

                .help,
                .filters,
                .selected {
                    display: flex;

                    .helpIcon {
                        &:hover {
                            color: var(--primary-theme-color);
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .loadingError {
            position: absolute;
            bottom: 0;
            width: 100%;
            padding: 7px 20px;
            display: flex;
            background: #f9d9d9;
            color: #d91726;
            justify-content: space-between;
            align-items: center;
            font-size: 0.8rem;

            .message {
                display: flex;
                overflow: hidden;

                .errorIcon {
                    margin-right: 8px;
                }

                p {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }

            .errorActions {
                .dismiss {
                    background: transparent;
                    border: none;
                    box-shadow: none;
                    color: #d91726;
                    opacity: 0.7;
                    font-size: 0.65rem;
                    margin: 0 !important;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.tooltip {
    padding: 8px 12px;
    width: 400px;
    .section {
        &:not(:last-child) {
            margin-bottom: 16px;
        }

        .header {
            font-weight: bold;
            color: var(--medium-text-color);
            font-size: 14px;
            margin-bottom: 4px;

            svg {
                color: var(--primary-theme-color-light);
                font-size: 12px;
                margin-right: 4px;
            }
        }

        .body {
            color: var(--light-text-color);
            line-height: 1.3em;
        }
    }
} */
