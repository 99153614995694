.BaseTable {
    font-size: 12px;
    background: unset;
    box-shadow: none;
}

.BaseTable__table {
    background: inherit;
}

.BaseTable--empty .BaseTable__table {
    outline: none;
}

.BaseTable__table-main {
    outline: 1px solid #e1e1e1;
}

.BaseTable__row {
    height: 40px;
    border-bottom: none;
    color: var(--medium-text-color);
}

.BaseTable__row:hover,
.BaseTable__row--hovered {
    background: inherit;
}

.BaseTable__row-cell {
    padding-left: 15px;
    border-bottom: 1px solid #eeeeee;
}

.BaseTable__row-cell-no-padding {
    padding: 0!important;
}

.BaseTable__header-row {
    border-bottom: none;
}

.BaseTable__header-row .BaseTable__header-cell {
    border-bottom: 1px solid #dedede;
    background-color: #ececec;
    padding-left: 15px;
}

.BaseTable__header-row .BaseTable__header-cell:not(:last-child) {
    border-right: 1px solid #cccccc;
}

.BaseTable__header-row .BaseTable__header-cell:hover {
    background-color: rgba(0, 0, 0, 0.08);
}

.BaseTable--empty {
    min-height: 300px;
}

.BaseTable__overlay,
.BaseTable__empty-layer {
    min-height: 300px;
    background-color: transparent;
    bottom: 14px!important;
}
